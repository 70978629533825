<template>
  <div class="addsend-wrap">
    <NavBar />
    <div class="content">
      <h1>
        <div>
          短信剩余条数：
          <span>{{ note.sms_num }}条</span>
        </div>
        <van-button round size="mini" color="#FFEFC5" @click="callPhone('073182297060')">我要充值短信</van-button>
      </h1>
      <div class="line1">
        <h2>短信主题</h2>
        <van-field v-model="param.crontab_title" placeholder="请输入短信主题" maxlength="10" />
      </div>
      <div class="line1">
        <h2>发送群体</h2>
        <radioField v-model="group" :columns="groupList" placement="bottom-end" placeholder="请选择群体" />
      </div>
      <div class="line2">
        <h2>群发内容</h2>
        <van-field v-model="sms_content" rows="3" autosize type="textarea" maxlength="280" placeholder="请输入短信类容" show-word-limit />
        <p>
          1、单条短信长度（即签名、短信内容和退订方式数字之和）不得超过280字。
          <br />
          2、超过70字，按60字/条，逐条计费。单个汉字、标点、英文都 算一个字、回车记两个字。
        </p>
      </div>
      <div class="line3">
        <div class="switch">
          <h2>周期群发</h2>
          <van-switch v-model="checked" size="20px" active-color="#09C89D" inactive-color="#dcdee0" />
        </div>
        <div v-if="checked">
          <div class="period">
            <h2>重复频率</h2>
            <div>
              <span v-bind:class="{ select: !Hz }" @click="Hz = false">按周</span>
              <span v-bind:class="{ select: Hz }" @click="Hz = true">按月</span>
            </div>
          </div>

          <div class="Time_selection_box">
            <div>
              <h2>发送日期</h2>
              <van-field v-show="Hz" v-model="date" @click="show1 = !show1" readonly right-icon="arrow" placeholder="请选择"></van-field>
              <van-field v-show="!Hz" v-model="week" @click="show1 = !show1" readonly right-icon="arrow" placeholder="请选择"></van-field>
            </div>
            <div>
              <h2>发送时间</h2>
              <van-field v-model="crontab_hour" @click="show2 = !show2" readonly right-icon="arrow" placeholder="请选择"></van-field>
            </div>
          </div>
          <p>请设置短信重复频率，可按每周一次或每月一次，然后选择具体 发送时间。</p>
        </div>
        <div v-else>
          <van-field
            v-model="Time"
            @click="
              show = !show;
              currentDate = sendingTime;
            "
            readonly
            :right-icon="require('@/assets/images/calendar.png')"
          ></van-field>
          <p>你可以选择任意时刻定时群发，成功设置后不支持修改，但在设定的时间之前可取消，取消后不占用群发条数</p>
        </div>
      </div>
      <button class="Lbutton" type="submit" value="取消" @click="$router.go(-1)">取消</button>
      <button class="Notice" type="submit" value="确定" @click="submit">确定</button>
      <!-- 选择年月日小时 -->
      <van-popup v-model="show" position="bottom" :style="{ height: '51%' }">
        <van-datetime-picker v-model="currentDate" type="datehour" title="选择年月日小时" :min-date="minDate" @cancel="show = !show" @confirm="confirm" />
      </van-popup>
      <!-- 选择发送日期 -->
      <van-popup v-model="show1" position="bottom">
        <gaoWeekPicker v-show="!Hz" v-model="crontab_week" radio @cancel="show1 = !show1"  @confirm="show1 = !show1" :list="weeklist" title="选择发送时间" />
        <gaoWeekPicker v-show="Hz" v-model="crontab_day" @cancel="show1 = !show1" round="round" @confirm="show1 = !show1" :list="daylist" title="选择发送日期" />
      </van-popup>
      <!-- 选择发送时间 -->
      <van-popup v-model="show2" position="bottom"><van-picker title="选择发送时间" show-toolbar :columns="hrList" @confirm="confirmHour" @cancel="show2 = false" /></van-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import table from '@/api/table';
import util from '@/utils/util.js';
import NavBar from '@/components/NavBar';
import { Toast } from 'vant';
import gaoWeekPicker from '@/components/gaoWeekPicker';
import radioField from '@/components/radioField';
export default {
  name: 'addsend',
  components: { NavBar, radioField, gaoWeekPicker },
  data() {
    return {
      checked: false, //控制定时发送
      Hz: false, //控制重复频率
      show: false, //控制时间选择器
      show1: false, //控制日期
      show2: false, //控制时间
      weeklist: [
        { title: '星期一', value: 1 },
        { title: '星期二 ', value: 2 },
        { title: '星期三', value: 3 },
        { title: '星期四', value: 4 },
        { title: '星期五', value: 5 },
        { title: '星期六 ', value: 6 },
        { title: '星期日', value: 0 }
      ],
      daylist: [],
      hrList: [],
      groupList: [], //群组list
      group: '', //选中的发送群体
      sms_content: '', //短信参数
      sendingTime: new Date(), //选择的定时发送时间
      minDate: new Date(), //最小可选择时间
      currentDate: new Date(), //当前时间选择器绑定的时间
      crontab_week: [], // 选中的周
      crontab_day: [], // 选中天
      date:'',// 选中的日期
      week:'',
      crontab_hour: '', // 选中小时
      param: {
        crontab_title: '',
        group_id: ''
      }
    };
  },
  computed: {
    ...mapState(['note']),
    Time: function() {
      return util.formatDate(this.sendingTime, 'yyyy-MM-dd hh');
    }
  },
  watch: {
    group: {
      handler(newVal) {
        this.param.group_id = newVal.id;
        this.sms_content = newVal.sms_content;
      },
      deep: true
    },
    crontab_day(newVal){
      this.date=newVal.map(item=>{return `${item}日`}).toString()
    },
    crontab_week(newVal){
      this.week=this.weeklist.filter(iten=>{return iten.value==newVal})[0].title
    }
  },
  created() {
    this.daylist = [];
    for (var i = 0; i < 30; i++) {
      this.daylist.push({ title: i + 1, value: i + 1 });
    }
    this.hrList = [];
    for (var i = 0; i < 11; i++) {
      this.hrList.push(i + 9);
    }
    this.memberGroup();
  },
  methods: {
    // 确定提交
    submit() {
      let { crontab_title, group_id } = this.param;
      let data = {};
      if (!crontab_title) {
        return Toast.fail('请输入短信主题');
      }
      if (!group_id) {
        return Toast.fail('请选择发送群体');
      }
      data.crontab_title = crontab_title;
      data.group_id = group_id;
      data.sms_content = this.sms_content;
      if (this.checked) {
        if (this.Hz) {
          if (this.crontab_day.length < 1) {
            return Toast.fail('请选择发送日期');
          }
          if (!this.crontab_hour) {
            return Toast.fail('请选择发送时间');
          }
          data.crontab_day = this.crontab_day;
          data.crontab_hour = parseInt(this.crontab_hour);
        } else {
          if (this.crontab_week.length < 1) {
            return Toast.fail('请选择发送日期');
          }
          if (!this.crontab_hour) {
            return Toast.fail('请选择发送时间');
          }
          data.crontab_week = this.crontab_week;
          data.crontab_hour = parseInt(this.crontab_hour);
        }
      } else {
        data.crontab_date = util.formatDate(this.sendingTime, 'yyyy-MM-dd');
        data.crontab_hour = parseInt(util.formatDate(this.sendingTime, 'hh'));
      }

      table.crontabAdd(data).then(response => {
        if (response.data.result) {
          this.$router.go(-1); //返回上一层
        }
      });
    },
    // 确定小时
    confirmHour(val) {
      this.crontab_hour = val;
      this.show2 = false;
    },
    // 群组列表
    memberGroup() {
      table.memberGroup({}).then(res => {
        this.groupList = res.data.list.map(item => {
          return JSON.parse(JSON.stringify(item).replace(/group_name/g, 'text'));
        });
      });
    },
    // 调用手机电话功能
    callPhone(phoneNumber) {
      location.href = 'tel://' + phoneNumber;
    },
    confirm(value) {
      this.show = false;
      this.sendingTime = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.addsend-wrap {
  .van-cell::after {
    border-bottom: none;
  }
  /deep/.van-picker__toolbar {
    height: 100px;
    border-bottom: 2px solid #f0f0f0;
    div {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
    button {
      font-size: 28px;
      font-weight: 500;
      color: #666;
    }
    .van-picker__confirm {
      color: #2c6bf8;
    }
  }
  .content {
    min-height: calc(100vh - 112px);
    padding: 0 30px;
    margin-top: 20px;
    background: #fff;
    .van-field {
      line-height: 70px;
      padding: 0 23px;
      background: #ffffff;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      /deep/.van-icon {
        display: flex;
        align-items: center;
        img {
          width: 33px;
          height: 31px;
        }
      }
    }
    h2 {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
    p {
      margin-top: 27px;
      font-weight: 500;
      font-size: 24px;
      color: #999999;
    }
    h1 {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #e9e9e9;
      > div {
        font-size: 26px;
        font-weight: bold;
        color: #333;
        span {
          color: #ff6600;
        }
      }
      button {
        color: #d05f00 !important;
        font-size: 22px;
        font-weight: 500;
        height: 42px;
        padding: 0 25px;
      }
    }
    .line1 {
      margin-top: 37px;
      h2 {
        margin-bottom: 30px;
      }
    }
    .line2 {
      margin-top: 50px;
      .van-field {
        padding-top: 20px;
        font-size: 26px;
        line-height: 32px;
      }
      h2 {
        margin-bottom: 27px;
      }
      /deep/.van-field__word-limit {
        color: #2c6bf8;
      }
    }
    .line3 {
      .switch {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
      }
      .period {
        display: flex;
        align-items: center;
        h2 {
          margin-right: 40px;
        }
        div {
          display: flex;
          background: #eeeeee;
          border-radius: 27px;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 53px;
            width: 85px;
            border-radius: 27px;
            font-size: 26px;
            font-weight: bold;
            color: #333333;
          }
          .select {
            background: #2c6bf8;
            color: #ffffff;
          }
        }
      }
      .Time_selection_box {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        /deep/ input{
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        h2 {
          margin-bottom: 28px;
        }
        .van-field {
          width: 328px;
        }
        p {
          font-size: 24px;
        }
      }
    }
    .Lbutton {
      border: 1px solid #2c6bf8;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      margin-top: 80px;
      margin-bottom: 50px;
      color: #2c6bf8;
      margin-left: 50px;
      font-size: 30px;
      background: #f4f8ff;
    }
    .Notice {
      border: 1px solid #2c6bf8;
      width: 300px;
      height: 80px;
      border-radius: 40px;
      margin-top: 80px;
      margin-bottom: 50px;
      color: #ffffff;
      font-size: 30px;
      margin-left: 40px;
      background-color: #2c6bf8;
    }
  }
}
</style>
