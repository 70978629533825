<!--
 功能名称:单选输入框
 开发人:梁高权
 创建时间:2021/05/31
 最后修改时间:2021/05/31
-->
<template>
  <div class="radioField-wrap">
    <van-field v-on:click="show=true" v-model="title" class="readonly-field" maxlength="10" type="text" :placeholder="placeholder" readonly  right-icon="arrow-down"/>
    <van-popup v-model="show" position="bottom">
      <van-picker title="选择群组" show-toolbar :columns="columns" @confirm="onSelect" @cancel="show = false" />
    </van-popup>
  </div>
</template>

<script>
export default{
  name:'radioField',
  props:['value','columns','placement','placeholder'],
  model:{
    prop:'value',
    event:'change'
  },
  data(){
    return{
      title:'',
      show: false,
    }
  },
  watch:{
    value(newVal){
      this.title=newVal.text
    }
  },
  methods:{
    onSelect(action) {
      console.log(action)
      this.show = false
      this.$emit('change',action)
    }
  }
}
</script>

<style lang="scss" scoped>
.radioField-wrap{
  /deep/.van-picker__toolbar {
    height: 100px;
    border-bottom: 2px solid #f0f0f0;
    div {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
    button {
      font-size: 28px;
      font-weight: 500;
      color: #666;
    }
    .van-picker__confirm {
      color: #2c6bf8;
    }
  }
  .van-field{
    line-height: 70px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 0 10px
  }
}

</style>
