<template>
  <div class="gaoWeekPicker-wrap">
    <div class="head">
      <span @click="$emit('cancel')">取消</span>
      <h5>{{title}}</h5>
      <span @click="confirm">确定</span>
    </div>
    <div class="content">
      <span :class="[choose(item.value),shape]" @click="select(item)" v-for="(item,index) in list" :key="index">{{item.title}}</span>
      <span :class="shape" style="height: 0;margin-top: 0;margin-bottom: 0;" ></span>
      <span :class="shape" style="height: 0;margin-top: 0;margin-bottom: 0;" ></span>
      <span :class="shape" style="height: 0;margin-top: 0;margin-bottom: 0;" ></span>
      <span :class="shape" style="height: 0;margin-top: 0;margin-bottom: 0;" ></span>
      <span :class="shape" style="height: 0;margin-top: 0;margin-bottom: 0;" ></span>
    </div>
  </div>
</template>

<script>
import util from '@/utils/util.js';
export default{
  name:'gaoWeekPicker',
  props:['checked','title','list','round','square', 'radio'],
  model:{
    prop:'checked',
    event:'change'
  },
  data(){
    return{}
  },
  computed:{
    choose() {
        return function(value){
          let a=''
          this.checked.forEach((x,index)=>{if(x===value){a="choose"}})
          return a
        }
    },
    shape(){
      if(typeof(this.round)!='undefined'){
        return 'round'
      }else{
        return 'square'
      }
    }
  },
  methods:{
    select(item){
      switch(util.varType(this.radio)){
        case 'string':
          this.radioSel(item)
          break;
        default:
          this.multipleSel(item)
      }

    },
    radioSel(item){
      this.checked.splice(0,this.checked.length)
      this.checked.push(item.value)
    },
    // 多选
    multipleSel(item){
      let bool=true
      this.checked.forEach((x,index)=>{
        if(x===item.value){
          this.checked.splice(index, 1)
          bool=false
        }
      })
      if(bool) this.checked.push(item.value)
    },
    confirm() {
      this.$emit('confirm',this.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.gaoWeekPicker-wrap{
  .head{
    display: flex;
    align-items: center;
    height: 100px;
    padding: 0 40px;
    font-size: 28px;
    font-weight: 500;
    color: #666666;
    border-bottom: 1px solid #F0F0F0;
    h5{
      flex:1;
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
    span:nth-child(3){color:#2C6BF8;}
  }
  .content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:40px 35px 40px;
    .square{
      text-align: center;
      width: 128px;
      font-size: 32px;
      font-weight: 500;
      color: #999999;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 20px;
    }
    .round{
      width: 60px;
      height: 60px;
      border-radius: 50%;
      font-size: 32px;
      font-weight: 500;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px;
      // margin-bottom: 15px;
    }

    .choose{
      background: #306EF8;
      color: #fff;
    }
  }
}
</style>
